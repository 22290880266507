*, *:before, *:after
  box-sizing border-box


.clearfix
  clearfix()

.accessible
  display inline-block
  text-indent -9999em
  outline 0

.upper
  text-transform uppercase

video::-webkit-media-controls
    display none !important

body.admin-bar
  #__bs_notify__
    top 32px !important

#__bs_notify__
  font-size 0 !important
  left 0 !important
  right 0 !important
  top 0 !important
  height 10px !important
  border-radius 0 !important
  background-color #2ecc71 !important
  padding 0 !important
  opacity .8
  pointer-events none

