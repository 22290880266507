.text-slider
  padding 0
  margin 0
  list-style none
  font-family 'Noto Serif', serif

  &-item
    padding 40px 0

    &-in
      max-width 520px
      margin auto

    p
      font-size 22px
      line-height 1.4
      margin 0

    .author
      font-size 18px
      opacity .5
      margin 20px 0 0 0
