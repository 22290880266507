.content-slider
  padding 0
  margin auto
  list-style none

  &-item
    text-align left

  &-item-in
    max-width 540px
    margin auto

.content-slider-titles-wrapper
  overflow hidden

.content-slider-titles
  padding 0
  margin 0
  list-style none
  overflow hidden
  text-align left
  font-size 0
  transition .5s ease transform
  transform translateX(0)

  .section-title
    opacity .5
    transition .4s ease opacity

    @media $600max
      font-size 18px

    @media $1000max
      font-size 32px

  li
    display inline-block
    vertical-align top
    text-align center

    &.is-active
      .section-title
        opacity 1

