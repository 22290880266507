.site-header
  position absolute
  z-index 1000
  left 0
  right 0
  top 20px

  @media $1000
    top 40px

  &-toggle
    float right
    margin 4px 0 0 0
