.site
  text-align center

  &-logo
    display inline-block
    width 196px
    height 32px
    background url(../images/logo.svg) no-repeat
    background-size 100%

    @media $1000
      width 294px
      height 48px

