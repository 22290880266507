.item-audio
  display block
  text-decoration none

  &-title
    font-size 18px
    color #fff
    font-weight normal
    margin 20px 0 0

  &-player
    background-color #fff
    margin 0
