.content-cols
  font-size 0

  &-col30
    padding 20px 0
    text-align left

    @media $600
      padding 0 50px

    @media $1000
      width 33.333%
      display inline-block
      vertical-align top
