.btn
  cursor pointer
  border 0
  text-decoration none
  border-radius $radius

  @media $1000
    transition .2s ease all

  &-white
    color #fff
