.site-cover
  padding 20px 0
  background-color $c-main
  background-image url(../images/site-cover-img.jpg)
  background-repeat no-repeat
  background-size cover
  background-position -200px top
  position relative
  color #fff

  &-bg
    content ''
    absolute left right top bottom
    background-color rgba(#000, .9)
    z-index 900
    opacity 0
    transition .4s ease all
    pointer-events none

    .nav-is-open &
      opacity 1

    @media $768
      display none

  @media $1000
    background-position left top
    overflow hidden

  &-video
    absolute left top
    z-index 5
    width 100%
    height 100%
    object-fit cover
    will-change contents

  &-in
    position relative
    z-index 100

  &-content
    margin auto
    margin-top 60px
    padding 30px 0
    max-width 300px

    @media $768
      float left
      max-width none
      padding 200px 0 30px 0

    @media $1000
      margin-left 5%

    @media $1200
      padding-top 300px

    @media $1600
      padding-top 400px
      margin-left 240px

  &-title
    font-weight normal
    font-size 60px
    margin 20px 0 0

    @media $768
      font-size 90px
      margin 40px 0 0

    @media $1200
      font-size 110px

  &-subtitle
    font-size 26px
    text-transform uppercase
    margin 0 0 50px

    @media $768
      font-size 34px

    @media $1000
      font-size 40px

  &-btns
    margin-top 20px

    @media $768
      margin-top 0

  &-btn
    display block
    border 2px solid #979797
    text-align center
    padding 10px 0
    max-width 300px
    margin auto

    &:first-child
      margin-bottom 10px

      @media $768
        margin 0 10px 0 0

    @media $768
      display inline-block
      padding 10px 20px

    @media $1000
      font-size 25px

      &:hover
        border-color #fff
        background-color rgba($c-main, .4)

  &-equity-placeholder
    height 42px

    @media $768
      display none

  &-equity
    width 120px
    height 83px
    background url(../images/equity.jpg) no-repeat
    background-size 120px 83px
    display block
    margin 0 auto
    absolute top 100% left right
    z-index 100
    margin-top -42px
    opacity 1
    transition .2s ease all

    .nav-is-open &
      opacity 0
      transform translateY(10px)

    @media $768
      top auto
      bottom 50px
      margin-top 0
      left auto

    @media $1000
      width 160px
      height 110px
      background-size 100%
