/*
 * Font Face Mixin
 * Usage:
 * font-face('FamilyName', 'FileName'[, 'weight'])
 */


font-face($family, $file, $weight = 'normal')
  $font-url = '../fonts/'

  @font-face
    font-family $family
    font-weight $weight
    font-style normal
    src url($font-url + $file + '.eot')
    src url($font-url + $file + '.eot?#iefix') format('embedded-opentype'), url($font-url + $file + '.woff') format('woff'), url($font-url + $file + '.ttf') format('truetype'), url($font-url + $file + '.svg#' + $file) format('svg')


.font-noto
  font-family 'Noto Serif', serif


