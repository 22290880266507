.item-video
  display block
  max-width 100%
  text-decoration none

  &-title
    font-size 18px
    color $c-main
    font-weight normal
    margin 20px 0 0

  &-img
    display block
    max-width 300px

  &-img-wrapper
    display block
    position relative

    &:before
    &:after
      content ''
      absolute left right top bottom

    &:before
      background-color $c-main
      opacity 0
      transition .6s linear opacity

    &:after
      background url(../images/icon-play.svg) center center no-repeat
      transform scale(0)
      transition .4s ease transform

  &:hover
    .item-video-img-wrapper
      &:before
        opacity .5

      &:after
        transform scale(1)
