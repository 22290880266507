.copyright
  background repeating-linear-gradient(-55deg, $c-main, $c-main 10px, #484848 10px, #484848 11px);
  color #fff
  overflow hidden
  padding 20px 0
  text-align center

  @media $600
    text-align left

  &-copy
    @media $600
      float left

  &-mango
    color #fff
    text-decoration none
    line-height 40px
    display block
    margin-top 20px

    @media $600
      margin-top 0
      float right

    span
      font-size 0
      width 38px
      height 40px
      display inline-block
      vertical-align top
      background url(../images/mangoweb.svg)
      position relative
      margin 0 4px
      transition .2s ease transform

    &:hover
      span
        transform scale(1.2)

  p
    margin 0
    font-size 16px
    line-height 40px
