.section-tabs
  &-nav
    text-align center
    margin 30px 0 70px 0

  &-nav-item
    display inline-block
    vertical-align top
    color $c-main
    text-decoration none
    border 1px solid $c-main
    padding 6px 32px
    font-size 14px
    margin 5px 10px

    @media $600
      margin 0 3px

    .view-dark &
      color #fff

    &.is-active
      background-color $c-main
      color #fff

      .view-dark &
        background-color #fff
        color $c-main

    &:hover
      border-style dashed

.section-tabs
  .view-flex
    display flex
    flex-wrap wrap
    justify-content center

    @media $600
      justify-content space-around

    @media $1000
      justify-content space-between

  .item-video
  .item-audio
    padding-bottom 50px
    margin 0 20px
    display inline-block

    @media $1200
      padding-bottom 100px

    @media $1200
      &:nth-last-child(-n+3)
        padding-bottom 0
