.section
  padding 50px 0
  text-align center

  &.view-light
    background-color #fff

  &.view-dark
    background-color $black
    color #fff

  &.view-gray
    background-color $c-light

  &.view-photos
    padding-bottom 0

  &-title
    font-size 34px
    line-height 1
    font-weight 300
    margin 0 0 40px 0
    color $c-main

    @media $600
      font-size 50px

    .view-dark &
      color #fff


