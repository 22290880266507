.site-nav-toggle
  width 35px
  height 30px
  position relative
  display inline-block

  @media $768
    display none

  span
    display block
    absolute left
    height 4px
    width 100%
    background #fff
    transition .25s ease-in-out

    &:nth-child(1)
      top 0

      .nav-is-open &
        top 10px
        width 0
        left 50%

    &:nth-child(2)
      top 10px

      .nav-is-open &
        transform rotate(45deg)

    &:nth-child(3)
      top 10px

      .nav-is-open &
        transform rotate(-45deg)

    &:nth-child(4)
      top 20px

      .nav-is-open &
        top 10px
        width 0
        left 50%

.site-nav
  @media $768max
    absolute left right top 60px
    padding 20px 0
    opacity 0
    transform translateY(-10px)
    transition .2s ease all
    pointer-events none

  @media $768
    float right
    margin 8px 0 0 0

  @media $1000
    margin-top 15px

  .nav-is-open &
    opacity 1
    transform translateY(0)
    pointer-events auto

  &-item
    display block
    color #fff
    text-decoration none
    text-align center
    text-transform uppercase
    font-size 22px
    padding 15px 0
    position relative

    @media $768
      display inline-block
      vertical-align top
      padding 0 10px
      font-size 14px

    @media $1000
      padding 0
      margin 0 10px

      &:after
        content ''
        absolute left right bottom -5px
        height 1px
        background-color #fff
        transform translateY(-5px)
        opacity 0
        transition .2s ease all

      &:hover:after
        transform translateY(0)
        opacity .8
