.basic-styles
  p, li
    font-size 16px

    @media $600
      font-size 18px

  ul
    list-style none
    margin 40px 0
    padding 0

    li
      margin 0 0 15px 0
      position relative
      padding-left 40px

      &:last-child
        margin-bottom 0

      &:before
        content ''
        absolute left top 8px
        width 10px
        height 10px
        background-color #fff
        border-radius 50%
