.photoset
  display table
  border-collapse collapse
  width 100%
  margin 0
  padding 0
  font-size 0

  li
    display table-cell
    vertical-align bottom

  img
    display block
    width 100%
    height auto
