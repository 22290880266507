.bx
  &-wrapper
    position relative

  &-controls-direction
    a
      width 100px
      font-size 0
      absolute top bottom left
      background-color $black
      display none

      @media $768
        display block

      &:after
        content ''
        width 100px
        absolute top bottom left
        background url('../images/icon-arrow-slider.svg') center center no-repeat
        opacity .5

      &.bx-next
        left auto
        right 0
        transform rotate(180deg)

      &:hover:after
        opacity .8

      &.disabled
        opacity 0
        pointer-events none
